/* App.css */

.item-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.item {
  flex: 1 1 30%; /* Each item will take up approximately one-third of the space */
  margin: 10px;
  box-sizing: border-box;
}

/* Media query for mobile view (optional) */
@media (max-width: 768px) {
  .item {
    flex: 1 1 100%; /* Each item takes up full width on mobile */
  }
}
body{
  padding-top:4rem;
  
}